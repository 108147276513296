<!-- // componets/EventItem.vue -->
<template>
  <li :class="[
    `${roomClass} relative p-4 mb-2 event transform transition-transform duration-300 hover:scale-105`,
    hoveredSlug === event.slug ? getRoomGradientClass(event.rooms[0]) : ''
  ]" @mouseenter="handleMouseEnter(event.slug)" @mouseleave="handleMouseLeave">
    <NuxtLink :to="`/events/${event.slug}`" class="flex flex-col lg:flex-row items-center justify-between">
      <div class="event flex w-full flex-row items-center justify-between lg:space-x-4">
        <div class="flex-grow flex flex-col md:flex-row items-center lg:space-x-4 text-center">
          <p class="room text-center md:text-left w-full md:w-1/4 text-sm uppercase">
            {{ getRoomNameById(event.rooms[0]?.id || event.rooms[0]) }}
          </p>
          <div class="w-full md:w-1/2">
            <div class="time mb-3 md:mb-0">
              <p v-if="isToday(normalizeDateField(event, 'start_date', 'startDate'))"
                class="flex justify-center items-center space-x-4">
                <span class="time">
                  {{ hour(normalizeDateField(event, 'start_date', 'startDate')) }}–{{ hour(normalizeDateField(event,
                    'end_date', 'endDate')) }}
                </span>
              </p>
              <p v-else>
                <span v-if="showDay">{{ day(normalizeDateField(event, 'start_date', 'startDate')) }},</span>
                {{ hour(normalizeDateField(event, 'start_date', 'startDate')) }}–{{ hour(normalizeDateField(event,
                  'end_date', 'endDate')) }}
              </p>
            </div>
            <h3 class="text-2xl lg:text-4xl">{{ event.title }}</h3>
          </div>
          <div class="flex justify-end w-full md:w-1/4" v-if="event && event.sourceType">
            {{ event.sourceType }}
            <UBadge v-if="event && event.source && event.source.__typename"
              class="bg-stone-900 dark:bg-cream text-cream dark:text-stone-900">
              Residency
            </UBadge>
          </div>
        </div>
      </div>
    </NuxtLink>
  </li>
</template>
<script setup lang="ts">
import { isToday } from "date-fns";
import { useDateFormat } from "@vueuse/core";

const props = defineProps({
  roomMapping: {
    type: Array,
    required: true,
    default: () => [],
  },
  event: {
    type: Object,
    required: true,
  },
  showDay: {
    type: Boolean,
    default: false,
  },
  hoveredSlug: {
    type: Number,
    required: true,
  },
  handleMouseEnter: {
    type: Function as PropType<(event: MouseEvent) => void>,
    required: true,
  },
  handleMouseLeave: {
    type: Function as PropType<(event: MouseEvent) => void>,
    required: true,
  },
});

const normalizeDateField = (event, apiField, graphQLField) => {
  return event[apiField] || event[graphQLField];
};

const day = (date) => {
  const parsedDate = new Date(date);
  return parsedDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

const hour = (date) => useDateFormat(ref(date), "h:mma").value;

const getRoomNameById = (roomId) => {
  if (!props.roomMapping || props.roomMapping.length === 0) return "";
  const room = props.roomMapping.find((room) => room.id === roomId);
  return room ? room.name : "";
};

const getRoomGradientClass = (roomId) => {
  const roomGradients = {
    "southern-cross": "noise-flamingo-gradient",
    "living-room": "noise-orange-gradient",
    "the-main-hall": "noise-blue-gradient",
    "unknown-room": "noise-default-gradient",
  };

  if (props.roomMapping && props.roomMapping.length > 0) {
    const room = props.roomMapping.find((room) => room.id === roomId);
    if (room && room.slug in roomGradients) {
      return roomGradients[room.slug];
    }
  }

  return "noise-default-gradient";
};

const roomClass = computed(() => {
  if (props.event.rooms[0] && props.event.rooms[0].slug) {
    return `room-${props.event.rooms[0].slug}`;
  }

  const roomId = props.event.rooms[0];
  const room = props.roomMapping.find((room) => room.id === roomId);
  return room ? `room-${room.slug}` : "";
});
</script>


<style scoped>
.hover-div {
  transition: all 0.3s ease-in-out;
}

li {

  &.event {
    @apply bg-stone-50 dark:bg-stone-700;
  }

  &.room {

    &-southern-cross {
      @apply bg-stone-100 dark:bg-stone-700;

    }

    &-living-room {
      @apply bg-stone-200 dark:bg-stone-600;
    }

    &-the-main-hall {
      @apply bg-stone-300 dark:bg-stone-950;
    }

  }
}

.event-list h3 {
  font-family: 'GT Alpina Trial Cn Th';
  list-style: none;
  padding: 0;
}

.hover-div {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  padding: 1rem;
  width: 100%;
  min-height: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  z-index: 100;
  pointer-events: none;
}

.vertical-text-left {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}

.vertical-text-right {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.grow-enter-active,
.grow-leave-active {
  transition: none;
}

.grow-enter-from,
.grow-leave-to {
  opacity: 0;
}

.grow-enter-to,
.grow-leave-from {
  opacity: 1;
}

.group,
.hover-div {
  cursor: pointer;
}
</style>
